@keyframes shakeAnimation {
  0%, 100% {
    transform: translateX(0);
  }

  10%, 30%, 50%, 65%, 90% {
    transform: translateY(-10px);
  }

  20%, 40%, 55%, 70% {
    transform: translateX(10px);
  }

  25%, 45%, 60%, 80% {
    transform: translateX(-10px);
  }
}

.elements {
  animation: 15s ease-in-out infinite shakeAnimation;
  position: absolute;
}

.hat {
  width: 130px;
  height: 130px;
  top: 0;
  left: -70px;
}

.bulb {
  width: 156px;
  height: 156px;
  top: 20%;
  right: 50px;
}

.books {
  width: 107px;
  height: 107px;
  top: 0;
  left: -50px;
}

.hatR {
  width: 140px;
  height: 140px;
  top: 0;
  right: -50px;
}

.gloab {
  width: 150px;
  height: 150px;
  bottom: 0;
  left: -100px;
}

.search {
  width: 154px;
  height: 154px;
  bottom: 150px;
  right: -80px;
}

.cup {
  width: 150px;
  height: 150px;
  top: 20px;
  left: -50px;
}

.hand {
  width: 150px;
  height: 150px;
  top: 50px;
  right: -150px;
}

.pp {
  z-index: -9;
  width: 679px;
  height: 716px;
  display: none;
  top: -380px;
  right: -450px;
}

.bb {
  z-index: -9;
  width: 374px;
  height: 433px;
  display: none;
  bottom: -80px;
  left: -390px;
}

.ppp {
  z-index: -99;
  width: 406px;
  height: 453px;
  display: none;
  bottom: -200px;
  right: -100px;
}

.pp2 {
  z-index: -99;
  width: 449px;
  height: 473px;
  display: none;
  top: -200px;
  left: -400px;
}

.bb3 {
  z-index: -99;
  width: 408px;
  height: 474px;
  display: none;
  top: -140px;
  right: -340px;
}

.plp {
  z-index: -99;
  width: 408px;
  height: 474px;
  display: none;
  top: -100px;
  left: -280px;
  transform: rotate(-90deg);
}

.pp22 {
  z-index: -99;
  width: 408px;
  height: 474px;
  display: none;
  top: -100px;
  right: -380px;
  transform: rotate(-45deg);
}

.bb2 {
  z-index: -99;
  width: 434px;
  height: 503px;
  display: none;
  bottom: -50px;
  left: -300px;
}

.ppp2 {
  z-index: -99;
  width: 406px;
  height: 453px;
  display: none;
  bottom: -452px;
  right: -250px;
}

/*# sourceMappingURL=index.efbb5730.css.map */
