/* animation */
@keyframes shakeAnimation {
    0%, 100% {
        transform: translateX(0);
    }
    10%, 30%, 50%, 65%, 90% {
        transform: translateY(-10px);
    }
    20%, 40%, 55%, 70% {
        transform: translateX(10px);
    }
    25%, 45%, 60%, 80% {
        transform: translateX(-10px);
    }
}
.elements{
    position: absolute;
    animation: shakeAnimation 15s infinite ease-in-out;
}
.hat{
    top: 0;
    left: -70px;
    width: 130px;
    height: 130px;
}
.bulb{
    top: 20%;
    right: 50px;
    width: 156px;
    height: 156px;
}
.books{
    top: -0px;
    left: -50px;
    width: 107px;
    height: 107px;
}
.hatR{
    top: 0;
    right: -50px;
    width: 140px;
    height: 140px;
}
.gloab{
    left: -100px;
    bottom: 0;
    width: 150px;
    height: 150px;
}
.search{
    bottom: 150px;
    right: -80px;
    width: 154px;
    height: 154px;
}
.cup{
    top: 20;
    left: -50px;
    width: 150px;
    height: 150px;
}
.hand{
    top: 50px;
    right: -150px;
    width: 150px;
    height: 150px;
}


/* background elements */
.pp{
    top: -380px;
    right: -450px;
    z-index: -9;
    width: 679px;
    height: 716px;
    display: none;
}
.bb{
    bottom: -80px;
    left: -390px;
    z-index: -9;
    width: 374px;
    height: 433px;
    display: none;
}
.ppp{
    bottom: -200px;
    right: -100px;
    z-index: -99;
    width: 406px;
    height: 453px;
    display: none;
}
.pp2{
    top: -200px;
    left: -400px;
    z-index: -99;
    width: 449px;
    height: 473px;
    display: none;
}
.bb3{
    top: -140px;
    right: -340px;
    z-index: -99;
    width: 408px;
    height: 474px;
    display: none;
}
.plp{
    top: -100px;
    left: -280px;
    z-index: -99;
    width: 408px;
    height: 474px;
    transform: rotate(-90deg);
    display: none;
}
.pp22{
    top: -100px;
    right: -380px;
    z-index: -99;
    width: 408px;
    height: 474px;
    transform: rotate(-45deg);
    display: none;
}
.bb2{
    bottom: -50px;
    left: -300px;
    z-index: -99;
    width: 434px;
    height: 503px;
    display: none;
}
.ppp2{
    bottom: -452px;
    right: -250px;
    z-index: -99;
    width: 406px;
    height: 453px;
    display: none;
}
/* background elements ends*/